export const tabs = [
  { name: 'HTML', active: false },
  { name: 'CSS', active: false },
  { name: 'Javascript', active: false },
  { name: 'Landing Page', active: false },
  { name: 'Multi Page', active: false },
  { name: 'Component', active: false },
  { name: 'ReactJS', active: false },
  { name: 'NodeJS', active: false },
  { name: 'Full Stack', active: false },
  { name: 'API', active: false },
  { name: 'Theme switch', active: false },
];
