export const skills = [
  'HTML',
  'CSS',
  'Javascript',
  'Git/GitHub',
  'Gatsby',
  'Node.JS',
  'Axios',
  'Semantic HTML',
  'Netlify',
  'Firebase',
  'Heroku',
  "API's",
  'Accessibility',
];
